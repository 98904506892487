
//jQuery.noConflict();
jQuery(document).ready(function($) {

    // Preloader
    $(window).on('load', function() { // makes sure the whole site is loaded 
        $('.preloader-gif').fadeOut(); // will first fade out the loading animation 
        $('.preloader-slider').delay(350000).fadeOut('slow'); // will fade out the white DIV that covers the website. 
        $('.owl-carousel').delay(350000).css({ 'overflow': 'visible' });
    });


    $(window).on('load', function () {

        // owlCarousel
        //$('.owl-carousel').owlCarousel();
        $('.slide-fotos').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 2
                },
                768: {
                    items: 3
                },
                1000: {
                    items: 4
                }
            }
        });



        $('.slide-principal').owlCarousel({
            pagination: true,
            nav: false,
            loop: true,
            dots: true,
            margin: 0,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            //animateIn: 'fadeIn',
            //animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }
        });

        $('.last-blog').owlCarousel({
            //pagination: true,
            nav: false,
            loop: false,
            dots: true,
            margin: 30,
           // stagePadding: 100,
            autoplay:false,
            autoplayTimeout:5000,
            autoplayHoverPause:true,
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },
                1000:{
                    items:3
                },
                1200: {
                    items: 3
                },
            }
            
        });

        $('.slide-single').owlCarousel({
            pagination: true,
            nav: false,
            loop: true,
            dots: true,
            margin: 0,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            //animateIn: 'fadeIn',
            //animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }

        });


        $('.slide-projectes, .slider-serveis, .slide-nosaltres').owlCarousel({
            pagination: true,
            nav: false,
            loop: true,
            dots: true,
            margin: 0,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            //animateIn: 'fadeIn',
            //animateOut: 'fadeOut',
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }

        });






        $('.slide-actividades, .slide-servicios, .slide-noticias').owlCarousel({
            pagination: true,
            nav: false,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1200: {
                    items: 3
                },
            }

        });


        $('.slide-products').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 3
                },
                1200: {
                    items: 4
                },
            }

        });



        $(".owl-prev").html('<span class="icon-carrow-left"></span>');
        $(".owl-next").html('<span class="icon-carrow-right"></span>');


        

    });



    // jquery code
    $(document).ready(function () {

        /*
        $('.slider-projectes').on('initialized.owl.carousel changed.owl.carousel', function(e) {
            if (!e.namespace)  {
            return;
            }
            var carousel = e.relatedTarget;
            $('.slider-counter').text(carousel.relative(carousel.current()) + 1 + ' / ' + carousel.items().length);
            })
            .owlCarousel({
            items: 1,
            loop:true,
            margin:0,
            nav:true
        });

        $('.slider-serveis').on('initialized.owl.carousel changed.owl.carousel', function(e) {
            if (!e.namespace)  {
            return;
            }
            var carousel = e.relatedTarget;
            $('.slider-counter-serveis').text(carousel.relative(carousel.current()) + 1 + ' / ' + carousel.items().length);
            })
            .owlCarousel({
            items: 1,
            loop:true,
            margin:0,
            nav:true
        });

        $('.slide-nosaltres').on('initialized.owl.carousel changed.owl.carousel', function(e) {
            if (!e.namespace)  {
            return;
            }
            var carousel = e.relatedTarget;
            $('.slider-counter-nosaltres').text(carousel.relative(carousel.current()) + 1 + ' / ' + carousel.items().length);
            })
            .owlCarousel({
            items: 1,
            loop:true,
            margin:0,
            nav:true
        });
        */


        // Bootstrap Auto-Hiding Navbar 
        $(".header").autoHidingNavbar();


        // Highlight the top nav as scrolling occurs
        $(window).scroll(function() {
            if ($(document).scrollTop() > 250) {
                $('.header').addClass('shrink');
            } else {
                $('.header').removeClass('shrink');
            }

            if ($(document).scrollTop() > 600) {
                $('.slide-principal').addClass('shrink');
            } else {
                $('.slide-principal').removeClass('shrink');
            }
         
        });


        // Select all links with hashes
        $('.menu-item a[href*="#"]') .not('[href="#"]') .not('[href="#0"]') .click(function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
                ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({scrollTop: target.offset().top}, 1000, function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();
                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } 
                        else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });


        // Add active dropdown
        /*
        $('.dropdown').hover(function(){ 
            $('.dropdown-toggle', this).trigger('click'); 
        });
        */


       // toggle menu
        $("body").on("click", ".menu-item a", function (e) {
            $(".navbar-toggler").trigger("click");
        });


      

        // Meni
        $('.navbar-toggler').click(function() {
            $('.navbar-toggler').toggleClass('collapsed');
            //$(this).addClass('current');
            $('.full-menu').toggleClass('active');
            var $target = $(this).attr('href');
            $($target).addClass('active');
            return false;
        });


        /*document.querySelector(".hamburguer").addEventListener("click", function(){
            document.querySelector(".full-menu").classList.toggle("active");
            document.querySelector(".hamburguer").classList.toggle("close-hamburguer");
        });*/


       // Empresas
        $('.colapsar').click(function() {
            $('.colapsar').removeClass('current');
            $(this).addClass('current');
            $('.box').removeClass('current');
            var $target = $(this).attr('href');
            $($target).addClass('current');
            return false;
        });

        // Custom list collapse
        // Esdeveniments 
        $('.custom-list a').click(function () {
            $('.custom-list a').removeClass('current');
            $(this).addClass('current');
            $('.box-area').removeClass('current');
            var $target = $(this).attr('href');
            $($target).addClass('current');
            return false;
        });


  




        // Animate elements when scrolled into view with native JavaScript
        var animateHTML = function() {
            var elems;
            var windowHeight;

            function init() {
                elems = document.querySelectorAll('.hidden');
                windowHeight = window.innerHeight;
                addEventHandlers();
                checkPosition();
            }

            function addEventHandlers() {
                window.addEventListener('scroll', checkPosition);
                window.addEventListener('resize', init);
            }

            function checkPosition() {
                for (var i = 0; i < elems.length; i++) {
                    var positionFromTop = elems[i].getBoundingClientRect().top;
                    if (positionFromTop - windowHeight <= 0) {
                        elems[i].className = elems[i].className.replace(
                            'hidden',
                            'fade-in-element'
                        );
                    }
                }
            }
            return {
                init: init
            };
        };
        animateHTML().init();




    // Scroll Animations
    // Function which adds the 'animated' class to any '.animatable' in view
    var doAnimations = function() {
    
    // Calc current offset and get all animatables
    var offset = $(window).scrollTop() + $(window).height(),
        $animatables = $('.animatable');
    
    // Unbind scroll handler if we have no animatables
    if ($animatables.length == 0) {
        $(window).off('scroll', doAnimations);
    }
    
    // Check all animatables and animate them if necessary
	$animatables.each(function(i) {
       var $animatable = $(this);
		if (($animatable.offset().top + $animatable.height() - 20) < offset) {
            $animatable.removeClass('animatable').addClass('animated');
			}
        });

	};
  
    // Hook doAnimations on scroll, and trigger a scroll
	$(window).on('scroll', doAnimations);
    $(window).trigger('scroll');











        // Animate on scroll
        // Detect request animation frame
        var scroll = window.requestAnimationFrame ||
                    // IE Fallback
                    function(callback){ window.setTimeout(callback, 1000/60)};
        var elementsToShow = document.querySelectorAll('.show-on-scroll'); 

        function loop() {

            Array.prototype.forEach.call(elementsToShow, function(element){
            if (isElementInViewport(element)) {
                element.classList.add('is-visible');
            } else {
                element.classList.remove('is-visible');
            }
            });

            scroll(loop);
        }

        // Call the loop for the first time
        loop();

        // Helper function from: http://stackoverflow.com/a/7557433/274826
        function isElementInViewport(el) {
        // special bonus for those using jQuery
        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }
        var rect = el.getBoundingClientRect();
        return (
            (rect.top <= 0
            && rect.bottom >= 0)
            ||
            (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight))
            ||
            (rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
        );
        }




        // https://simpleparallax.com/
        /*
        var image = document.getElementsByClassName('parallax');
        new simpleParallax(image, {
            delay: .6,
            transition: 'cubic-bezier(0,0,0,1)',
            //scale: 1.5
        });
        */

        $(function() {
            var $el = $('.parallax-background');
            $(window).on('scroll', function () {
                var scroll = $(document).scrollTop();
                $el.css({
                    //'background-position':'50% '+(-.4*scroll)+'px'
                    'background-position':'100% '+(+.4*scroll)+'px'
                });
            });
        });


        





        // magnificPopup for .image-link
        $('.image-link').magnificPopup({ type: 'image' });



        $('.grid-gallery').each(function() { // the containers for all your galleries
            $(this).magnificPopup({
                delegate: 'a', // the selector for gallery item
                type: 'image',
                gallery: {
                enabled:true
                }
            });
        });


        $('.popup-gallery, .slide-single').magnificPopup({
            delegate: '.owl-item:not(.cloned) a',
            type: 'image',
            removalDelay: 500, //delay removal by X to allow out-animation
            callbacks: {
                beforeOpen: function () {
                    // just a hack that adds mfp-anim class to markup 
                    this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
            tLoading: 'Loading image #%curr%...',
            mainClass: 'mfp-img-mobile',
            gallery: {
                enabled: true,
                navigateByImgClick: true,
                preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
            },
            image: {
                tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                titleSrc: function (item) {
                    return item.el.attr('title') + '<small></small>';
                }
            }
        });



        // Popup
        $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
            disableOn: 700,
            type: 'iframe',
            mainClass: 'mfp-fade',
            removalDelay: 160,
            preloader: false,

            fixedContentPos: false
        });


        // Cambiar clases botones
  

        $('.woocommerce-MyAccount-content .woocommerce-Input').addClass('form-control');
        

        

        $('.single_add_to_cart_button').addClass('btn btn-primary');
        $('.single_add_to_cart_button').removeClass('button');
        

        $('.wc-forward').addClass('btn btn-primary');
        $('.wc-forward').removeClass('button');


        $('a.checkout-button ').addClass('btn');
        $('a.checkout-button ').removeClass('button');


        $('input[type="submit"]').addClass('btn');
        $('input[type="submit"]').removeClass('button');


        // the selector for gallery item
        $('.woocommerce-product-gallery__wrapper').each(function() { // the containers for all your galleries
            $(this).magnificPopup({
                delegate: 'a', // the selector for gallery item
                type: 'image',
                gallery: {
                enabled:true
                }
            });
        });
        


        // Contact
        $('.wpcf7-form .wpcf7-text').addClass('form-control');
        $('.wpcf7-textarea').addClass('form-control');
        $('.wpcf7-date').addClass('form-control');
        $('.wpcf7-submit').addClass('btn btn-primary');
        //$('.input-xlarge').addClass('form-control');


    
        



    });


});
